import {Injectable} from '@angular/core';
import {User} from "../models/user";
import {map, Observable, Observer} from "rxjs";
import {Router} from "@angular/router";
import {AlertService} from "./alert.service";
import {RestApiService} from "@makeo-packages/mkongtools";
import {MatDialog} from "@angular/material/dialog";
import {VerifyCodeComponent} from "../views/auth/verify-code/verify-code.component";


/**
 * Service d'authentification de l'application
 * (pas seulement double-auth mais nommé ainsi pour differenciation auth.service.ts, angular fuse et/ou makeo packages)
 */
@Injectable({
  providedIn: 'root'
})
export class DoubleAuthService {

  private user?: User;
  public accessToken?: string;
  public lastDoubleAuthToken?: string;


  constructor(
    private api: RestApiService,
    private alertService: AlertService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.accessToken = localStorage.getItem("token"); // permet de conserver la connection à l'ouverture d'un nouvel onglet
  }

  registerUser(user: User) {
    return this.api.post("user/register", user);
  }

  setAccessToken(token: string) {
    this.accessToken = token;
    localStorage.setItem("token", token)
  }

  signIn(email: string, password: string) {
    return this.api.post("login_check", {
      username: email,
      password: password
    }).pipe(map((token: any) => {
      this.accessToken = token.token as string;
      localStorage.setItem("token", token.token as string);

    }));
  }

  /**
   * Function to ask server to create a double auth code (+token) and send it by mail to the connecter user / to the mail to verify
   * @param token
   * @param context
   * @param email
   * @param redirection
   * @param action
   */
  createDoubleAuthCode(context?: string, redirection?:string, token?: string, email?:string, action?:any) {
    this.setLastDoubleAuthToken(context + '_'); // reset eventual previous token and update context
    let formData = {
      context: context,
      redirection: redirection,
      token: token,   // let it empty for new token generation
      email: email,
      action: action
    }
    console.log('createDoubleAuthCode() formData:', formData);
    return this.api.post("double-auth/create-code", formData).pipe(map((response: any) => {
      let generatedDoubleAuthToken = response.datas.double_auth_token
      if(!generatedDoubleAuthToken){
        this.alertService.error("Erreur lors de la génération du jeton d'identification.")
      }
      this.setLastDoubleAuthToken(generatedDoubleAuthToken);
    }));
  }

  verifyDoubleAuthCode(doubleAuthToken: string, doubleAuthCode: string) {
    return this.api.post("double-auth/verify-code", {
      token: doubleAuthToken,
      code: doubleAuthCode
    });
  }

  resendDoubleAuthCode(doubleAuthToken: string){
    return this.api.post("double-auth/re-send", {
      token: doubleAuthToken
    });
  }

  userInfos() {
    if (this.user) {
      return new Observable((observer: Observer<any>) => {
        observer.next(this.user)
        observer.complete()
      })
    }
    return this.api.get("user/infos").pipe(map((response: any) => {
      this.user = response.datas as User;
      return this.user;
    }));
  }

  userInfosObfuscated() {
    if (this.user) {
      return new Observable((observer: Observer<any>) => {
        observer.next(this.user)
        observer.complete()
      })
    }
    return this.api.get("user/infos-obfuscated").pipe(map((response: any) => {
      this.user = response.datas as User;
      return this.user;
    }));
  }

  isAdmin() {
    return this.user?.roles?.includes("ROLE_ADMIN") as boolean;
  }

  getBrowserUrlAnchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }

  getUser(){
    return this.user;
  }

  getAccessToken() {
    if (localStorage.getItem("token")) {
      this.accessToken = localStorage.getItem("token") ?? undefined;
    }
    return this.accessToken;
  }

  getLastDoubleAuthToken() {
    if (localStorage.getItem("double-auth-token")) {
      this.lastDoubleAuthToken = localStorage.getItem("double-auth-token") ?? undefined;
      console.log("VERIF localStorage.getItem(\"double-auth-token\") : '" + this.lastDoubleAuthToken + "' ");
    }
    return this.lastDoubleAuthToken;
  }

  setLastDoubleAuthToken(doubleAuthToken : string){
    localStorage.setItem("double-auth-token", doubleAuthToken);
    console.log("VERIF localStorage.setItem(\"double-auth-token\", '" + doubleAuthToken + "' ");
  }

  determineContextOfDoubleAuthToken(doubleAuthToken : string){
    //exemple le register du doubleAuthToken : "register_172.22.0.1_670784a37abf8"
    console.log('determineContextOfDoubleAuthToken #' + doubleAuthToken + '#');
    if(!doubleAuthToken){
      return '';
    }
    return doubleAuthToken.split('_')[0];
  }

  signOut() {
    this.accessToken = undefined;
    this.user = undefined;
    localStorage.removeItem("token");
    localStorage.removeItem("double-auth-token");
    this.router.navigate(['/'])
  }




}
